import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { AppInsightsMonitoringService } from './app-insights-monitoring.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppInsightsGlobalErrorHandler extends ErrorHandler {
    constructor(private injector: Injector) {
        super();
    }

    handleError(error: Error): void {
        if (environment.production === true && environment.appinsights_instrumentationkey !== undefined) {
            const appInsightsMonitoringService = this.injector.get(AppInsightsMonitoringService);
            appInsightsMonitoringService.logError(error);
        }
        super.handleError(error);
    }
}
