import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Role } from '../models/role';
import { User } from '../models/user';
import { Warehouse } from '../models/warehouse';

const CURRENT = 'current';
const EXIST_USERNAME = 'exist_username';
const SLASH = '/';
const URI_PATH = '/users';
const SPECIFIC_USER = 'user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentUser$ = new BehaviorSubject<User>(undefined);

  private currentUserPermissions$ = new BehaviorSubject<Role>(undefined);
  private currentUserPermissions: Role;

  constructor(private http: HttpClient) {
  }

  /**
   * @description returns an observable with the current value of user
   * @returns {Observable<User>}
   */
   public getCurrentLoggedUser(): Observable<User> {
    return this.currentUser$.asObservable();
  }

  /**
   * @description sets a user as current and notifies subscribers about the change
   * @param {User} user to set as current
   * @returns {void}
   */
  public setCurrentLoggedUser(user: User): void {
    this.currentUser$.next(user);
  }

  /**
   * @description returns an observable with the current value of user permissions
   * @returns {Observable<Role>}
   */
  public getCurrentPermissions(): Observable<Role> {
    return this.currentUserPermissions$.asObservable();
  }

  /**
   * @description sets a role as current and notifies subscribers about the change
   * @param {Role} role to set as current
   * @returns {void}
   */
  public setCurrentPermission(role: Role): void {
    this.currentUserPermissions = role;
    this.currentUserPermissions$.next(role);
  }

  /**
   * @description Setting warehouse to user.
   * @param warehouse
   * @return Observable<Warehouse>
   */
  public setActualWarehouse(warehouse: Warehouse): Observable<Warehouse> {
    return this.http.post<Warehouse>(environment.api_url + URI_PATH + SLASH + 'set-actual-warehouse', warehouse);
  }

  /**
   * @description Get all registered users in the current warehouse.
   * @returns {Observable<User[]>}
   */
  public getAll(): Observable<User[]> {
    return this.http.get<User[]>(environment.api_url + URI_PATH);
  }

  /**
   * @description get a specific user by id.
   * @param {number} id of specific user
   * @returns {Observable<User>}
   */
  public getById(id: number): Observable<User> {
    return this.http.get<User>(environment.api_url + URI_PATH + SLASH + SPECIFIC_USER + SLASH + id);
  }

  /**
   * @description get current user data
   * @returns {Observable<User>}
   */
  public getCurrentUser(): Observable<User> {
    return this.http.get<User>(environment.api_url + URI_PATH + SLASH + CURRENT);
  }

  /**
   * @description delete a specific user by id.
   * @param {User} user specific user to be deleted
   * @returns {Observable<User>}
   */
  public delete(user: User): Observable<User> {
    return this.http.delete<User>(environment.api_url + URI_PATH + SLASH + user.id);
  }

  /**
   * @description verifies if the username already exists.
   * @param {string} userName to verify
   * @returns {Observable<boolean>}
   */
  public userNameExist(userName: string): Observable<boolean> {
    return this.http.get<boolean>(environment.api_url + URI_PATH + SLASH + EXIST_USERNAME + SLASH + userName);
  }

  /**
   * @description save a basic data of a user. if isEdit is true, the user is updated otherwise is created.
   * @param {User} user to save
   * @param {boolean} isEditing flag to know if the user is being edited or created
   * @returns {Observable<User>}
   */
  public save(user: User, isEditing: boolean): Observable<User> {
    return isEditing
      ? this.http.put<User>(environment.api_url + URI_PATH, user)
      : this.http.post<User>(environment.api_url + URI_PATH, user);
  }
}
