import { catchError } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import * as _ from 'lodash';

import { CONSTANTS } from './../../shared/constants/constants';
import { KeycloakAuthenticationService } from './keycloak-authentication.service';


@Injectable({
  providedIn: 'root'
})
export class KeycloakTokenInterceptorService implements HttpInterceptor {

  constructor(private keycloakAuthService: KeycloakAuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authToken = this.keycloakAuthService.getToken();

    if (authToken !== null) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`
        }
      });
    }
    return next.handle(request).pipe(
      catchError(err => {
        if (!_.isNil(err.error) && _.isEqual(err.error.code, CONSTANTS.HTTP_STATUS.UNAUTHORIZED)) {
          this.keycloakAuthService.userLogOut();
        }
        return throwError(err);
      })
    );
  }
}
