import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { IMenuInterface } from 'scf-library/lib/scf-lib/scf-nav-components/scf-sidebar/menu-interface';
import { ISubMenuInterface } from 'scf-library/lib/scf-lib/scf-agenda-interfaces/sub-menu.interface';
import { mergeMap } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { ScfCommunicationService } from 'scf-library';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { AppInsightsMonitoringService } from './services/appInsights/app-insights-monitoring.service';
import { KeycloakAuthenticationService } from './authentication/keycloak/keycloak-authentication.service';
import { NOTIFICATION_TYPE } from '../app/shared/constants/notification-type';
import { Role } from './models/role';
import { RoleService } from './services/role.service';
import { ROUTES } from './shared/menu.routes';
import { User } from './models/user';
import { UserService } from './services/user.service';
import { Warehouse } from '../app/models/warehouse';
import { WarehouseService } from './services/warehouse.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  public currentUser: { given_name: string };
  public items: IMenuInterface[];
  public userName: string;
  public warehouses: Warehouse[];
  private appInsights;
  public showMenu = true;

  constructor(
    private scfComService: ScfCommunicationService,
    private roleService: RoleService,
    private keycloakAuthService: KeycloakAuthenticationService,
    private router: Router,
    private translate: TranslateService,
    private warehouseService: WarehouseService,
    private userService: UserService,
    private notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    this.translate.setDefaultLang('es');
    this.translate.use('es');
    this.items = this.initSidebar(ROUTES);
    this.appInsights = new AppInsightsMonitoringService(router);

  }

  public ngOnInit(): void {
    this.evaluateFrameParams();
    this.subscribeToScfEvents();
    this.getWarehouses();
    this.setCurrentUserPermissions();
    this.getUser();

  }

  /**
   * @description Initializes sidebar items
   * @param menus
   */
  private initSidebar(menus: IMenuInterface[]): IMenuInterface[] {
    return _.forEach(menus, (menu) => {
      this.translate.get(menu.title).subscribe((translation: string) => {
        menu.title = translation;
      });
      // If item has sub-items, iterate over them
      if (!_.isEmpty(menu.items)) {
        // Return ordered next level
        menu.items = this.initSubMenu(menu.items);
      }
    });
  }

  private initSubMenu(submenus: ISubMenuInterface[]): ISubMenuInterface[] {
    const translatedItems = _.forEach(submenus, (submenu) => {
      // Translate
      this.translate.get(submenu.title).subscribe((translation: string) => {
        submenu.title = translation;
      });
    });
    // Sort by title
    return _.sortBy(translatedItems, (item) => {
      return item.title;
    });
  }

  /**
   * @description Add subscribers for SCF service
   * @private
   */
  private async subscribeToScfEvents(): Promise<void> {
    // Sidebar & Search Box navigates to
    this.scfComService.navigateToSubscribe()
      .subscribe(async (routerLink: string) => {
        if (routerLink) {
          this.scfComService.showLoader(true);
          this.router.navigate([routerLink]).then(() => {
            this.scfComService.showLoader(false);
          }).catch(() => {
            this.scfComService.showLoader(false);
          });
        }
      });
    // Change warehouse
    this.scfComService.warehouseChangedSubscribe()
      .subscribe(async (warehouseId: number) => {
        if (warehouseId) {
          this.scfComService.showLoader(true);
          const selectedWarehouse = _.find(this.warehouses, ['id', warehouseId]);
          this.userService.setActualWarehouse(selectedWarehouse).subscribe((currentWarehouse: Warehouse) => {
            this.warehouseService.setCurrentWarehouse(currentWarehouse);
            const currentURL = this.router.url;
            this.router.navigate([currentURL]);
            this.scfComService.showLoader(false);
            this.translate.get('WAREHOUSES.MESSAGES.UPDATE_SUCCESS').subscribe(successMessage => {
                this.notifier.notify(NOTIFICATION_TYPE.SUCCESS, successMessage);
              }
            );
          }, () => {
            this.scfComService.showLoader(false);
            this.translate.get('WAREHOUSES.MESSAGES.UPDATE_ERROR').subscribe(errorMessage => {
              this.notifier.notify(NOTIFICATION_TYPE.SUCCESS, errorMessage);
            });
          });
        }
      });
    // Logout
    this.scfComService.logoutSubscribe()
      .subscribe((navRequest: any) => {
        if (_.isNil(navRequest)) {
          this.keycloakAuthService.userLogOut();
        }
      });
  }

  /**
   * Get Warehouses from backend
   * @private
   */
  private async getWarehouses(): Promise<void> {
    this.route.queryParams.subscribe(params => {
      this.warehouseService.getWarehouses().subscribe(warehouses => {
        this.warehouses = warehouses;
        const selected: Warehouse[] = _.filter(warehouses, warehouse => warehouse.isSelected);
        if ('warehouse' in params) {
          const warehouseFromURL = _.find(this.warehouses, ['name', params['warehouse']]);
          if (!_.isNil(warehouseFromURL)) {
            selected.push(warehouseFromURL);
          } else {
            selected.push(new Warehouse());
          }
        }

        if (!_.isEmpty(selected)) {
          const warehouse = selected.pop();
          this.warehouseService.setCurrentWarehouse(warehouse);
          this.userService.setActualWarehouse(warehouse);
        }
      });
    });
  }

  /**
   * @description Set current user and his permissions
   * @returns {void}
   */
  private setCurrentUserPermissions(): void {
    this.userService.getCurrentUser().pipe(
      mergeMap((currentUser: User) => {
        this.userService.setCurrentLoggedUser(currentUser);
        return this.roleService.getRoleById(currentUser.roleId);
      })
    ).subscribe((role: Role) => {
      this.userService.setCurrentPermission(role);
    });
  }

  /**
   * Get user for set username
   * @private
   * @description Set username for show in header
   * @return void
   */
  private async getUser(): Promise<void> {
    this.keycloakAuthService.getUserInfo().then(user => {
        if (!_.isNil(user)) {
          this.currentUser = user;
        }
      }
    );
  }

  /**
   * @private
   * @description Evaluate hide menus param
   * @return void
   */
  private evaluateFrameParams(): void {
    this.route.queryParams.subscribe(params => {
      if ('showMenu' in params) {
        this.showMenu = !_.isEqual(params['showMenu'], 'false');
      }
    });
  }
}
