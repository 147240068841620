import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../services/authentication.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})

export class LoginComponent {
    public loginUserData = { email: '', password: '' };

    constructor(
        private authService: AuthenticationService,
        private router: Router
    ) { }

    public onClickLoginUser() {
        this.authService
            .loginUser(this.loginUserData)
            .subscribe(
                res => {
                    this.authService.setToken = res.token;
                    this.router.navigate(['/profile']);
                },
                err => console.log(err)
            );
    }
}
