import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns an array of elements who aren't in other array
 */
@Pipe({
  name: 'notInArray',
  pure: false
})
export class NotInArrayPipe implements PipeTransform {
  /**
   * PipeFunction
   * @param items Items to search in the other array
   * @param secondArray Array to compare
   */
  transform(items: Object[], secondArray: Object[]): Object[] {
    if (!items || !secondArray) {
      return items;
    }
    return items.filter(item => secondArray.indexOf(item) === -1 );
  }
}
