import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isEqual as _isEqual } from 'lodash';

import { environment } from '../../environments/environment';
import { Role } from '../models/role';

const ROLE_URI = '/roles';
const SLASH = '/';
const VERIFY_NAME = 'name';

@Injectable()
export class RoleService {

  constructor(private http: HttpClient) { }
  /**
   * @description call to the api and gets all registered roles
   * @returns {Observable<Roles[]}
   */
  public getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(environment.api_url + ROLE_URI);
  }
  /**
   * @description call to the api and gets the information from a role
   * @param {number} id of the role from which the information is to be obtained
   * @returns {Observable<Role>}
   */
  public getRoleById(id: number): Observable<Role> {
    return this.http.get<Role>(environment.api_url + ROLE_URI + SLASH + id);
  }
  /**
   * @description call to the api to delete a specified role
   * @param {number} id of the role to be delete
   * @returns {Observable<Role>}
   */
  public deleteRole(id: number): Observable<Role> {
    return this.http.delete<Role>(environment.api_url + ROLE_URI + SLASH + id);
  }

  /**
   * @description call to api and check if the role name is already registered
   * @param {string} name name to be checked
   * @returns {Observable<boolean>}
   */
  public roleNameExist(name: string): Observable<boolean> {
    return this.http.get<boolean>(environment.api_url + ROLE_URI + SLASH + VERIFY_NAME + SLASH + name);
  }

  /**
   * @description call to the api to save a specified role and his permissions by each menu
   * @param {role} role - Role to be created
   * @param {string} mode - Mode to do
   * @returns {Observable<Role>}
   */
  public saveRole(role: Role, mode: 'create'| 'update'): Observable<Role> {
    return _isEqual(mode, 'create')
      ? this.http.post<Role>(environment.api_url + ROLE_URI, role)
      : this.http.put<Role>(environment.api_url + ROLE_URI, role);
  }
}

