import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { LoginComponent } from './components/login/login.component';
import { LoginModule } from './components/login/login.module';
import { AuthenticationService } from './services/authentication.service';
import { AuthenticationGuard } from './guards/authentication.guard';
import { TokenInterceptorService } from './services/token-interceptor.service';

@NgModule({
    imports: [
        FormsModule,
        HttpClientModule,
        LoginModule
    ],
    declarations: [],
    exports: [
        LoginComponent
    ],
    providers: [
        AuthenticationService,
        AuthenticationGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        }
    ]
})

export class AuthenticationModule { }
