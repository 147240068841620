import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
const routes: Routes = [
  // Assignment
  {
    path: 'assignment',
    loadChildren: () => import('./components/assignment/assignment.module')
      .then(m => m.AssignmentModule)
  },
  // Home
  {
    path: 'home',
    loadChildren: () => import('./components/home/home.module')
      .then(m => m.HomeModule)
  },
  // Containers
  {
    path: 'container',
    loadChildren: () => import('./components/container/container.module')
      .then(m => m.ContainerModule)
  },
  // Location
  {
    path: 'location',
    loadChildren: () => import('./components/location/location.module')
      .then(m => m.LocationModule)
  },
  {
    path: 'location/add-location',
    loadChildren: () => import('./components/location/add-location/add-location.module')
      .then(m => m.AddLocationModule)
  },
  {
    path: 'location/edit-location',
    loadChildren: () => import('./components/location/add-location/add-location.module')
      .then(m => m.AddLocationModule)
  },
  // WorkArea
  {
    path: 'work-area',
    loadChildren: () => import('./components/work-area/work-area.module')
      .then(m => m.WorkAreaModule)
  },
  {
    path: 'work-area/add-work-area',
    loadChildren: () => import('./components/work-area/add-work-area/add-work-area.module')
      .then(m => m.AddWorkAreaModule)
  },
  {
    path: 'work-area/edit-work-area',
    loadChildren: () => import('./components/work-area/add-work-area/add-work-area.module')
      .then(m => m.AddWorkAreaModule)
  },
  // Login
  {
    path: 'login',
    loadChildren: () => import('./authentication/components/login/login.module')
      .then(m => m.LoginModule)
  },
  // Product
  {
    path: 'product',
    loadChildren: () => import('./components/product/product.module')
      .then(m => m.ProductModule)
  },
  {
    path: 'product/add-product',
    loadChildren: () => import('./components/product/add-product/add-product.module')
      .then(m => m.AddProductModule)
  },
  {
    path: 'product/edit-product',
    loadChildren: () => import('./components/product/add-product/add-product.module')
      .then(m => m.AddProductModule)
  },
  {
    path: 'product/import-product',
    loadChildren: () => import('./components/product/import-product/import-product.module')
      .then(m => m.ImportProductModule)
  },
  // Role
  {
    path: 'role',
    loadChildren: () => import('./components/role/role.module')
      .then(m => m.RoleModule)
  },
  {
    path: 'role/add-role',
    loadChildren: () => import('./components/role/add-role/add-role.module')
    .then(m => m.AddRoleModule)
  },
  {
    path: 'role/details',
    loadChildren: () => import('./components/role/add-role/add-role.module')
      .then(m => m.AddRoleModule)
  },
  {
    path: 'role/edit-role',
    loadChildren: () => import('./components/role/add-role/add-role.module')
    .then(m => m.AddRoleModule)
  },
  // User
  {
    path: 'user',
    loadChildren: () => import('./components/user/user.module')
      .then(m => m.UserModule)
  },
  {
    path: 'user/add-user',
    loadChildren: () => import('./components/user/add-user/add-user.module')
      .then(m => m.AddUserModule)
  },
  {
    path: 'user/edit-user',
    loadChildren: () => import('./components/user/add-user/add-user.module')
      .then(m => m.AddUserModule)
  },
  // Archiving configuration
  {
    path: 'archiving-settings',
    loadChildren: () => import('./components/archiving-settings/archiving-settings.module')
      .then(m => m.ArchivingSettingsModule)
  },
  // Warehouse
  {
    path: 'warehouse',
    loadChildren: () => import('./components/warehouse/warehouse.module')
      .then(m => m.WarehouseModule)
  },
  {
    path: 'warehouse/add-warehouse',
    loadChildren: () => import('./components/warehouse/add-warehouse/add-warehouse.module')
      .then(m => m.AddWarehouseModule)
  },
  {
    path: 'warehouse/edit-warehouse',
    loadChildren: () => import('./components/warehouse/add-warehouse/add-warehouse.module')
      .then(m => m.AddWarehouseModule)
  },
  // mobile picking profile configuration
  {
    path: 'mobile-picking-profile',
    loadChildren: () => import('./components/mobile-picking-profile/mobile-picking-profile.module')
      .then(m => m.MobilePickingProfileModule)
  },
  {
    path: 'mobile-picking-profile/add-profile',
    loadChildren: () => import('./components/mobile-picking-profile/add-profile/add-profile.module')
      .then(m => m.AddProfileModule)
  },
  {
    path: 'mobile-picking-profile/edit-profile',
    loadChildren: () => import('./components/mobile-picking-profile/add-profile/add-profile.module')
      .then(m => m.AddProfileModule)
  },
  {
    path: 'mobile-picking-profile/profile',
    loadChildren: () => import('./components/mobile-picking-profile/add-profile/add-profile.module')
      .then(m => m.AddProfileModule)
  },
  // 404
  {
    path: '**',
    redirectTo: '/home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
