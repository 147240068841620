export const environment = {
  production: true,
  api_url: 'https://632-review-develop-3zknud.netlogistik.com/api',
  wma_url: 'http://localhost:3000/api',
  appinsights_instrumentationkey: '011b50d2-cac2-4815-b409-301574654e8f',
  FILE_IMPORT_API: {
    'URI': '',
    'KEY': '',
    'MODULES': {
      'ROUTE': {
        'KEY': '',
        'ID': ''
      }
    }
  },
  KEYCLOAK_SERVER_AUTH_URL: 'https://signin.netlogistik.com/auth/',
  KEYCLOAK_REALM: 'ops_supplynet',
  KEYCLOAK_CLIENT_ID: 'ops_develop_client'
};
