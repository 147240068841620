import { Injectable } from '@angular/core';
import { KeycloakInstance, KeycloakConfig } from 'keycloak-js';
import { environment } from '../../../environments/environment';
import * as Keycloak from 'keycloak-js';

const KEYCLOAK_ERROR = 'Authentication keycloak error: ';

@Injectable({
  providedIn: 'root'
})
export class KeycloakAuthenticationService {

  private keycloakAuthService: KeycloakInstance;

  private keycloakConfig: KeycloakConfig = {
      url: environment.KEYCLOAK_SERVER_AUTH_URL,
      realm: environment.KEYCLOAK_REALM,
      clientId: environment.KEYCLOAK_CLIENT_ID,

    };

  public init(): Promise<void | Error> {
    return new Promise(async (resolve, reject) => {
      try {
        this.keycloakAuthService = Keycloak(this.keycloakConfig);
        await this.keycloakAuthService.init(
          {
            onLoad: 'login-required'
          });
        resolve();
      } catch (error) {
        reject(error);
        throw new Error(`${KEYCLOAK_ERROR} ${error}`);
      }
    });
  }

  /**
   * @description Retrieve the keycloak service instance.
   * @returns {KeycloakInstance} - Keycloak instance
  */
  public getKeycloakAuthService(): KeycloakInstance {
    try {
      return this.keycloakAuthService;
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Retrieve the keycloak service token.
   * @returns {string} - Encoded token.
  */
  public getToken(): string {
    try {
      return (!!this.keycloakAuthService) ? this.keycloakAuthService.token : null;
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Retrieve the parsed keycloak service token.
   * @returns {object} - Parsed token.
  */
  public getTokenParsed(): object {
    try {
      return this.keycloakAuthService.tokenParsed;
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Returns the token validity.
   * @param {number} - Remaining seconds before the token expires.
   * @returns {boolean} - Token expired.
  */
  public isTokenExpired(minValidity?: number): boolean {
    try {
      return this.keycloakAuthService.isTokenExpired(minValidity);
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Redirects to logout.
  */
  public async userLogOut(): Keycloak.KeycloakPromise<void, void> {
    try {
      return await this.keycloakAuthService.logout();
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Redirects to user account management console.
  */
  public async userAccountManagement(): Keycloak.KeycloakPromise<void, Error> {
    try {
      return await this.keycloakAuthService.accountManagement();
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Returns whether the user is authenticated or not.
   * @returns {boolean} - User authenticated.
  */
  public userAuthenticated(): boolean {
    try {
      return this.keycloakAuthService.authenticated;
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Returns an array of realm roles associated with the user.
   * @returns {Array<string>} - Realm roles.
  */
  public getUserAssociatedRealmRoles(): Array<string> {
    try {
      return this.keycloakAuthService.realmAccess.roles;
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Returns an array of resource roles associated with the user.
   * @returns {any} - Resource roles.
  */
  public getUserAssociatedResourceRoles(): any {
    try {
      return this.keycloakAuthService.resourceAccess;
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Returns whether the user has the real role associated or not.
   * @param {string} - Realm role that is compared within the token.
   * @returns {boolean} - Associated realm role.
  */
  public userHasRealmRole(role: string): boolean {
    try {
      return this.keycloakAuthService.hasRealmRole(role);
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Returns whether the user has the resource role associated or not.
   * @param {string} - Resource role that is compared within the token.
   * @returns {boolean} - Associated resource role.
  */
  public userHasResourceRole(role: string): boolean {
    try {
      return this.keycloakAuthService.hasResourceRole(role);
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Returns the user profile information.
   * @returns {promise<any>} - User profile information.
  */
  public getUserProfile(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(this.keycloakAuthService.loadUserProfile());
      } catch (error) {
        reject(error);
        throw new Error(`${KEYCLOAK_ERROR} ${error}`);
      }
    });
  }

  /**
   * @description Returns the user information within the token.
   * @returns {promise<any>} - User information.
  */
  public getUserInfo(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(this.keycloakAuthService.loadUserInfo());
      } catch (error) {
        reject(error);
        throw new Error(`${KEYCLOAK_ERROR} ${error}`);
      }
    });
  }
}
