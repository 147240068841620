import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import { UserInterface } from '../interfaces/user.interface';

@Injectable()
export class AuthenticationService {
    private LOGIN_URL = environment.api_url + '/login';

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    /**
     * Return login user data response from server to frontend.
     *
     * @param user
     * @returns {Observable<Object>}
     */
    public loginUser(user) {
        return this.http.post<any>(this.LOGIN_URL, user);
    }

    /**
     * get user login status
     *
     * @returns {boolean}
     */
    public get isLoggedIn(): boolean {
        const user = this.getCurrentUser;
        if (user && user.payload) {
            return user.payload.exp > Date.now() / 1000;
        } else { return false; }
    }

    /**
     * get current (logged in) user
     *
     * @returns {UserInterface}
     */
    public get getCurrentUser(): UserInterface {
        const token = this.getToken;
        if (token) {
            let payload = token.split('.')[1];
            payload = window.atob(payload);
            return JSON.parse(payload);
        } else { return null; }
    }

    /**
     * remove token to logout the user.
     */
    public logoutUser() {
        this.removeToken();
        this.router.navigate(['/login']);
    }

    /**
     * set token
     *
     * @param value
     */
    public set setToken(value) {
        localStorage.setItem('token', value);
    }

    /**
     * check if token is available
     *
     * @returns {boolean}
     */
    public get isToken(): boolean {
        return localStorage.getItem('token') !== null;
    }

    /**
     * get token
     *
     * @returns {string | null}
     */
    public get getToken() {
        if (this.isToken) {
            return localStorage.getItem('token');
        }
    }

    /**
     * remove token
     */
    public removeToken() {
        if (this.isToken) {
            localStorage.removeItem('token');
        }
    }
}
