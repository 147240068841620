export class CONSTANTS {
  public static readonly ACTIONS = {
    DELETE: 'delete',
    EDIT: 'edit',
    VIEW: 'view',
    VIEW_DETAIL: 'view_detail'

  };
  public static readonly EMPTY_STRING = '';
  public static readonly ID = 'id';

  public static readonly WORK_AREA_URI_PATH = '/work-areas';

  public static readonly ERRORS = {
    API: {
      GENERAL: 'GENERAL.ERROR',
      OFFLINE: 'GENERAL.OFFLINE'
    },
    FIELDS_NAME: {
      LOCATION: {
        AISLE: 'aisle',
        CHECK_DIGITS: 'checkDigits',
        LOCATION_NAME: 'locationName',
        PRE_AISLE: 'preAisle',
        SIDE: 'side',
        SLOT: 'slot'
      }
    },
    PREFIXES: {
      LOCATION: {
        ERROR_PREFIX_LOCATION: 'LOCATION.ERRORS.',
        ERROR_TO_AISLE: '_AISLE',
        ERROR_TO_CHECK_DIGITS: '_CHECK_DIGITS',
        ERROR_TO_LOCATION_NAME: '_LOCATION_NAME',
        ERROR_TO_PRE_AISLE: '_PRE_AISLE',
        ERROR_TO_SIDE: '_SIDE',
        ERROR_TO_SLOT: '_SLOT'
      },
      PROFILE: {
        MOBILE_PICKING: 'PROFILES.MOBILE_PICKING.ERRORS'
      },
      WORK_AREA: {
        ERROR_PREFIX_WORK_AREA: 'WORK_AREA.ERRORS.',
        ERROR_TO_NUMBER: '_NUMBER',
        ERROR_TO_NAME: '_NAME',
        ERROR_TO_DESCRIPTION: '_DESCRIPTION',
        ERROR_TO_PROFILE: '_PROFILE',
      },
      ROLE: {
        ERROR_PREFIX_ROLE: 'ROLE.ERRORS.',
        ERROR_TO_NAME: '_ROLE_NAME',
        ERROR_TO_DESCRIPTION: '_ROLE_DESCRIPTION'
      }
    }
  };
  public static readonly CREATE = 'create';
  public static readonly DATE_FORMAT = { year: 'numeric', month: 'long', day: 'numeric' };
  public static readonly DOT = '.';
  public static readonly ERROR_PREFIX_USER = 'USER.ERRORS';
  public static readonly FILTER_TYPE = {
    ONLY_SEARCH: 0,
    FILTER: 1,
    SEARCH: 2,
  };
  public static readonly FORM_ERROR_ALPHANUMERIC = 'alphanumeric';
  public static readonly FORM_ERROR_MAX_LENGTH = 'maxlength';
  public static readonly FORM_ERROR_NO_WHITE_SPACES = 'whiteSpace';
  public static readonly FORM_ERROR_REQUIRED = 'required';
  public static readonly FORM_ERROR_ROLE_NAME_ALREADY_EXIST = 'roleName';
  public static readonly FORM_ERROR_USER_NAME = 'userNameAlreadyExist';
  public static readonly GRID_DEFAULT_PAGE_SIZE = 10;
  public static readonly GRID_EVENT_DELETE = 'delete';
  public static readonly GRID_EVENT_DETAILS = 'details';
  public static readonly GRID_EVENT_EDIT = 'edit';
  public static readonly GRID_EVENT_MENU_PERMISSION_TOGGLED = 'MENU_PERMISSION_TOGGLED';
  public static readonly GRID_EVENT_MODULE_PERMISSION_TOGGLED = 'MODULE_PERMISSION_TOGGLED';
  public static readonly GRID_EVENT_ROW_DESELECTED = 'rowDeselected';
  public static readonly GRID_EVENT_ROW_SELECTED = 'rowSelected';
  public static readonly INVALID_FORM = 'INVALID';
  public static readonly MAX_PRIORITIES = 10;
  public static readonly MIN_PRIORITY = 1;
  // EXCEL CONFIGS
  public static readonly MAX_EXCEL_SIZE = 2048;
  public static readonly MENU_NAME_PRODUCT = 'Products';
  public static NO_DATA = '-';
  public static readonly HTTP_STATUS = {
    UNAUTHORIZED: 401,
    INTERNAL_SERVER_ERROR: 500
  };
  public static readonly ICON_DETAIL = 'visibility';
  public static readonly ICON_DIRECTIONS_WALK = 'directions_walk';
  public static readonly ICON_PENDING = 'pending';
  public static readonly ICON_SCHEDULE = 'schedule';
  public static readonly ICON_TASK_ALT = 'task_alt';
  public static readonly LABEL_BASIC_INFO = 'GENERAL.BASIC_INFO';
  public static readonly LABEL_NO_DATA = 'GRID.NO_DATA';
  public static readonly LABEL_OFFLINE = 'GENERAL.OFFLINE';
  public static readonly MEGABYTE_MULTIPLIER = 1024;
  public static readonly MENU_ACTION_CLOSE = 'MENU.ACTIONS.CLOSE';
  public static readonly MENU_NAME_ROLE = 'Roles';
  public static readonly MENU_NAME_USER = 'Users';
  public static readonly MODAL_RESPONSE_CONFIRMED = 'Confirm';
  public static readonly MODAL_TYPE_CONFIRMATION = 'Confirmation';
  public static readonly MODAL_WITH = '500px';
  public static readonly NUMBER_EIGHT = 8;
  public static readonly NUMBER_FIFTY = 50;
  public static readonly NUMBER_FIVE = 5;
  public static readonly NUMBER_FIVE_HUNDRED = 500;
  public static readonly NUMBER_FOUR = 4;
  public static readonly NUMBER_INDEX_NOT_FOUND = -1;
  public static readonly NUMBER_ONE = 1;
  public static readonly NUMBER_TEN = 10;
  public static readonly NUMBER_THREE = 3;
  public static readonly NUMBER_TWO = 2;
  public static readonly NUMBER_ZERO = 0;
  public static readonly OPS_OVERVIEW_ART_IMAGE_PATH = '/assets/images/home.svg';
  public static readonly OPS_OVERVIEW_HEADER = '/assets/images/home_header.svg';
  public static readonly OPS_OVERVIEW_TRANSLATION_LABEL = 'HOME.OPS_OVERVIEW';
  public static readonly PATH_HOME = '/home';
  public static readonly PATH_MP_PROFILE = '/mobile-picking-profile';
  public static readonly PATH_MP_PROFILE_CREATE = 'add-profile';
  public static readonly PATH_MP_PROFILE_EDIT = 'edit-profile';
  public static readonly PATH_MP_PROFILE_VIEW = 'profile';
  public static readonly PATH_ROLE = '/role';
  public static readonly PATH_USER = 'user';
  public static readonly PATH_USER_CREATE = 'add-user';
  public static readonly PATH_USER_EDIT = 'edit-user';
  public static readonly ROLE_ADMIN = 'Admin';
  public static readonly ROLE_MESSAGE_PREFIX = 'ROLE.MESSAGES';
  public static readonly SLASH = '/';
  public static readonly TRANSLATE_PREFIX = {
    PICK: 'PICK.LABELS.',
    ASSIGNMENT: 'ASSIGNMENT.LABELS.'
  };
  public static readonly TIMEZONE = {
    UTC: 'UTC'
  };
  public static readonly QUICK_FILTER = {
    ASSIGNMENT: {
      RECENT: 'FILTER.RECENT',
      IN_PROGRESS: 'FILTER.IN_PROGRESS',
      COMPLETED: 'FILTER.COMPLETED',
      PENDING: 'FILTER.PENDING'
    }
  };
  public static readonly FIELDS = {
    ASSIGNMENT: {
      CREATED : 'created',
      STATUS:  'status',
      TYPE: 'type',
      ASSIGNMENT: 'assignment',
      ASSIGNMENT_NUMBER: 'assignment.number'
    }
  };
  public static readonly LOCATION_LABEL_DETAIL = 'LOCATION.LABELS.DETAILS';
  public static readonly WORK_AREA_LABEL_DETAIL = 'WORK_AREA.LABELS.DETAILS';
  public static readonly UI_LABELS = {
    GENERAL: {
      ACCEPT: 'GENERAL.ACCEPT',
      CANCEL: 'GENERAL.CANCEL'
    }
  };
  public static readonly UPDATE = 'update';
  public static readonly UNDERSCORE = '_';
  public static readonly USER_GREETING_TRASNLATION_LABEL = 'HOME.USER_GREETING';
  public static readonly USER_MESSAGE_PREFIX = 'USER.MESSAGES';
  public static readonly WAREHOUSE_LABEL_DETAIL = 'WAREHOUSES.LABELS.DETAILS';
  public static readonly UNDEFINED = undefined;
  public static readonly WORD_RECEIVES = 'Receives';
}
