import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ExcelTemplate } from '../models/excel-template';
import { environment } from '../../environments/environment';

const URI_PATH = `${environment.api_url}/templates`;

@Injectable()
export class TemplateService {

  constructor(private http: HttpClient) {
  }

  /**
   * @description Get a excel template
   * @returns {Observable<ExcelTemplate[]>} work areas list observable
   */
  public getTemplate(id: number): Observable<ExcelTemplate> {
    return this.http.get<ExcelTemplate>(`${URI_PATH}/${id}`);
  }

  /**
   * @description Saves new work area
   * @param {WorkArea} excelTemplate WorkArea data to create
   * @returns {Observable<ExcelTemplate>} WorkArea observable
   */
  public saveTemplate(excelTemplate: ExcelTemplate): Observable<ExcelTemplate> {
    return this.http.post<ExcelTemplate>(URI_PATH, excelTemplate);
  }

  /**
   * @description Get templates
   * @return {Observable<ExcelTemplate[]>}
   */
  public getTemplates(): Observable<ExcelTemplate[]> {
    return this.http.get<ExcelTemplate[]>(URI_PATH);
  }
}
