import { IMenuInterface } from 'scf-library/lib/scf-lib/scf-nav-components/scf-sidebar/menu-interface';

export const ROUTES: IMenuInterface[] = [
  // Home
  {
    title: 'MENU.HOME',
    icon: 'home',
    routerLink: 'home',
  },
  // Tasks
  {
    title: 'MENU.TASKS.TASKS',
    icon: 'picking',
    items: [
      {
        title: 'MENU.TASKS.PICKING',
        routerLink: 'assignment'
      }
    ]
  },
  // Catalogs
  {
    title: 'MENU.CATALOGS.TITLE',
    icon: 'catalogs',
    items: [
      {
        title: 'MENU.CATALOGS.PRODUCTS',
        routerLink: 'product'
      },
      {
        title: 'MENU.CATALOGS.LOCATIONS',
        routerLink: 'location'
      },
      {
        title: 'MENU.CATALOGS.CONTAINERS',
        routerLink: 'container'
      },
      {
        title: 'MENU.CATALOGS.WORK_AREAS',
        routerLink: 'work-area'
      },
      {
        title: 'MENU.CATALOGS.WAREHOUSES',
        routerLink: 'warehouse'
      }
    ]
  },
  // Configuration
  {
    title: 'MENU.CONFIGURATION.TITLE',
    icon: 'configuration',
    items: [
      {
        title: 'MENU.CONFIGURATION.ROLES',
        routerLink: 'role'
      },
      {
        title: 'MENU.CONFIGURATION.USERS',
        routerLink: 'user'
      },
      {
        title: 'MENU.CONFIGURATION.MP_PROFILES',
        routerLink: 'mobile-picking-profile'
      },
      {
        title: 'MENU.CONFIGURATION.ARCHIVING_CONFIGURATION',
        routerLink: 'archiving-settings'
      }
    ]
  }
];
