import { Pipe, PipeTransform } from '@angular/core';

/**
 * Filter models from a search model
 */
@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  /**
   * Pipe Function
   * @param items Models to filter
   * @param filter Search model
   */
  transform(items: Object[], filter: Object): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter(item => {
      let isValid = false;
      for (const key in filter) {
        if (!isValid && item[key] && filter[key]) {
          isValid = (
            item[key].toString().toLowerCase()
              .indexOf(filter[key].toString().toLowerCase()) !== -1);
        }
      }
      return isValid;
    });
  }
}
