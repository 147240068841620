import { ScfGridColumn } from 'scf-library';
import { WarehouseInterface } from 'scf-library/lib/scf-lib/scf-nav-components/scf-warehouse-selector/warehouse-interface';

import { User } from 'src/app/models/user';

export class Warehouse implements WarehouseInterface {
  public id: number;
  public name: string;
  public description?: string;
  public notes?: string;
  public timeZone?: string;
  public updated?: Date;
  public created?: Date;
  public isNewSite?: boolean;
  public isSelected?: boolean;
  public updatedBy?: User;

  /**
   * Defines column header labels
   */
  public static getColumnDefinitions(): ScfGridColumn[] {
    return [
      {field: 'name', header: 'WAREHOUSES.LABELS.NAME'},
      {field: 'description', header: 'WAREHOUSES.LABELS.DESCRIPTION'},
    ];
  }

  /**
   * Defines labels
   */
  public static getLabels() {
    return {
      accept: 'MENU.ACTIONS.ACCEPT',
      basicInfo: 'WAREHOUSES.LABELS.BASIC_INFO',
      cancel: 'GENERAL.CANCEL',
      create: 'WAREHOUSES.LABELS.CREATE',
      created: 'COMMON.LABELS.CREATED',
      delete: 'WAREHOUSES.LABELS.DELETE',
      description: 'WAREHOUSES.LABELS.DESCRIPTION',
      name: 'WAREHOUSES.LABELS.NAME',
      notes: 'WAREHOUSES.LABELS.NOTES',
      save: 'GENERAL.SAVE',
      timeZone: 'WAREHOUSES.LABELS.TIMEZONE',
      title: 'MENU.CATALOGS.WAREHOUSES',
      update: 'WAREHOUSES.LABELS.UPDATE',
      updated: 'COMMON.LABELS.UPDATED',
      updatedBy: 'COMMON.LABELS.UPDATED_BY',
      view: 'WAREHOUSES.LABELS.VIEW'
    };
  }

  /**
   * Defines warehouse error messages
   */
  public static getErrorMessages() {
    return {
      nameIsRequired: 'WAREHOUSES.ERRORS.NAME_IS_REQUIRED'
    };
  }

  /**
   * Defines warehouse messages
   */
  public static getMessages() {
    return {
      createError: 'WAREHOUSE.MESSAGES.CREATE_ERROR',
      createSuccess: 'WAREHOUSES.MESSAGES.CREATE_SUCCESS',
      deleteConfirm: 'WAREHOUSES.MESSAGES.DELETE_CONFIRM',
      deleteSuccess: 'WAREHOUSES.MESSAGES.DELETE_SUCCESS',
      updateError: 'WAREHOUSE.MESSAGES.UPDATE_ERROR',
      updateSuccess: 'WAREHOUSES.MESSAGES.UPDATE_SUCCESS',
      warehouseNotFound: 'WAREHOUSES.MESSAGES.NOT_FOUND'
    };
  }
}
