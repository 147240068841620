import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const INSIGHTS_PAGEVIEW_ERROR = 'Application Insights page view cannot be tracked: ';
const INSIGHTS_EVENT_ERROR = 'Application Insights Event cannot be tracked: ';
const INSIGHTS_ERROR_ERROR = 'Application Insights Error cannot be tracked: ';
const INSIGHTS_USERAUTH_ERROR = 'Application Insights user authentication cannot be set: ';
const INSIGHTS_CLEARAUTH_ERROR = 'Application Insights user authentication cannot be cleared: ';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsMonitoringService {

  private routerSubscription: Subscription;
  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appinsights_instrumentationkey
    }
  });

  constructor(private router: Router) {
    if (environment.production === true && this.appInsights.config.instrumentationKey !== undefined) {
      this.appInsights.loadAppInsights();
      this.routerSubscription = this.router.events.pipe(
        filter(event => event instanceof ResolveEnd))
        .subscribe((event: ResolveEnd) => {
          const activatedComponent = this.getActivatedComponent(event.state.root);
          if (activatedComponent) {
            this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
          }
        }
      );
    }
  }

  /**
   * @description Log pages views caught.
   * @param {string} name - Name of the page view.
   * @param {string} uri - Relative or absolute URL that identifies the page.
   * @param {dictionary} properties - Additional data used to filter pages.
   * @param {string} refUri - URL of the previous page that sent the user to the current page.
  */
  public logPageView(name?: string, uri?: string, properties?: { [key: string]: any },
    refUri?: string): void {
      try {
        if (environment.production === true && this.appInsights.config.instrumentationKey !== undefined) {
          this.appInsights.trackPageView({ name, uri, properties, refUri });
        }
      } catch (error) {
        throw new Error(`${INSIGHTS_PAGEVIEW_ERROR} ${error}`);
      }
  }

  /**
   * @description Log events caught.
   * @param {string} name - Name of the event.
   * @param {dictionary} properties - Additional data used to filter events.
  */
  public logEvent(name: string, properties?: { [key: string]: any }): void {
    try {
      if (environment.production === true && this.appInsights.config.instrumentationKey !== undefined) {
        this.appInsights.trackEvent({ name, properties });
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_EVENT_ERROR} ${error}`);
    }
  }

  /**
   * @description Log exceptions caught.
   * @param {Error} error - Error object.
   * @param {dictionary} properties - Additional data used to filter exceptions.
   * @param {number} severityLevel - Severity of the exception.
  */
  public logError(error: Error, properties?: { [key: string]: any }, severityLevel?: number): void {
    try {
      if (environment.production === true && this.appInsights.config.instrumentationKey !== undefined) {
        this.appInsights.trackException({ error, properties, severityLevel });
      }
    } catch (logError) {
      throw new Error(`${INSIGHTS_ERROR_ERROR} ${logError}`);
    }
  }

  /**
   * @description Set the authenticated user id and the account id.
   * Use this when you have identified a specific signed-in user.
   * @param {string} userId - User id identifier.
   * @param {string} accountId - User account identifier.
   * @param {boolean} storeInCookie - Cookie to set them for all events within the whole session.
  */
  public setAuthenticatedUserId(userId: string, accountId?: string, storeInCookie?: boolean): void {
    try {
      if (environment.production === true && this.appInsights.config.instrumentationKey !== undefined) {
        this.appInsights.setAuthenticatedUserContext(userId, accountId, storeInCookie);
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_USERAUTH_ERROR} ${error}`);
    }
  }

  /**
   * @description Clears the authenticated user id and the account id from the user context,
   * and clears the associated cookie.
  */
  public clearUserId(): void {
    try {
      if (environment.production === true && this.appInsights.config.instrumentationKey !== undefined) {
        this.appInsights.clearAuthenticatedUserContext();
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_CLEARAUTH_ERROR} ${error}`);
    }
  }

  /**
   * @description Trace the component of the innermost route.
   * @param {ActivatedRouteSnapshot} snapshot - Route associated with a component loaded.
   * @returns {string|any} - Component traced
  */
  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): string | any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }

  /**
   * @description Trace the route template.
   * @param {ActivatedRouteSnapshot} snapshot - Route associated with a component loaded.
   * @returns {string} - Route template traced
  */
  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }
    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }
    return path;
  }
}
