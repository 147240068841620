import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { environment } from '../../environments/environment';
import { Warehouse } from '../models/warehouse';

const SLASH = '/';
const URI_PATH = '/warehouses';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {
  private currentWarehouse$ = new ReplaySubject<Warehouse>();

  constructor(private http: HttpClient) { }

  /**
   * @description returns an observable with the current value of warehouse
   * @returns {Observable<Warehouse>}
   */
  public getCurrentWarehouse(): Observable<Warehouse> {
    return this.currentWarehouse$;
  }

  /**
   * @description sets a warehouse as current and notifies subscribers about the change
   * @param {Warehouse} warehouse to set as current
   * @returns {void}
   */
  public setCurrentWarehouse(warehouse: Warehouse): void {
    this.currentWarehouse$.next(warehouse);
  }

  /**
   * @description Gets warehouse list
   * @returns Warehouse list observable
   */
  getWarehouses(): Observable<Warehouse[]> {
    return this.http.get<Warehouse[]>(environment.api_url + URI_PATH);
  }

  /**
   * @description Saves new warehouse
   * @param newWarehouse Warehouse data to create
   * @returns Warehouse observable
   */
  saveWarehouse(newWarehouse: Warehouse): Observable<Warehouse> {
    return this.http.post<Warehouse>(environment.api_url + URI_PATH, newWarehouse);
  }

  /**
   * @description Saves updated warehouse
   * @param updatedWarehouse Warehouse data to update
   * @returns Warehouse observable
   */
  updateWarehouse(updatedWarehouse: Warehouse): Observable<Warehouse> {
    return this.http.put<Warehouse>(environment.api_url + URI_PATH + SLASH + updatedWarehouse.id, updatedWarehouse);
  }

  /**
   * @description Deletes selected warehouse
   * @param deletedWarehouse Warehouse data to delete
   * @returns Warehouse observable
   */
  deleteWarehouse(deletedWarehouse: Warehouse): Observable<Warehouse> {
    return this.http.delete<Warehouse>(environment.api_url + URI_PATH + SLASH + deletedWarehouse.id);
  }
}
