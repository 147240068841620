import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NotifierModule } from 'angular-notifier';
import { ScfCommunicationService, ScfHeaderModule, ScfLoaderModule, ScfSidebarModule } from 'scf-library';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { TemplateService } from 'src/app/services/template.service';

import { AppComponent } from './app.component';
import { AppInsightsGlobalErrorHandler } from './services/appInsights/app-insights-global-error-handler';
import { AppInsightsMonitoringService } from './services/appInsights/app-insights-monitoring.service';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { KeycloakAuthenticationService } from './authentication/keycloak/keycloak-authentication.service';
import { KeycloakTokenInterceptorService } from './authentication/keycloak/keycloak-token-interceptor.service';
import { NOTIFIER_DEFAULT_OPTIONS } from './util/notifier-config';
import { FilterPipe } from './util/FilterPipe';
import { NotInArrayPipe } from './util/NotInArrayPipe';
import { UserService } from './services/user.service';
import { RoleService } from './services/role.service';

export function kcFactory(keycloakService: KeycloakAuthenticationService) {
  return () => keycloakService.init();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    FilterPipe,
    NotInArrayPipe
  ],
  imports: [
    AppRoutingModule,
    AuthenticationModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    HttpClientModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    NotifierModule.withConfig(NOTIFIER_DEFAULT_OPTIONS),
    ScfHeaderModule,
    ScfLoaderModule,
    ScfSidebarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AppInsightsMonitoringService,
    KeycloakAuthenticationService,
    ScfCommunicationService,
    UserService,
    RoleService,
    TemplateService,
    {provide: ErrorHandler, useClass: AppInsightsGlobalErrorHandler},
    {provide: APP_INITIALIZER, useFactory: kcFactory, deps: [KeycloakAuthenticationService], multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: KeycloakTokenInterceptorService, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
