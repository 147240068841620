import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
    constructor(private authService: AuthenticationService) { }

    /**
     * set token in request header
     *
     * @param req
     * @param next
     * @returns {any}
     */
    intercept(req, next) {
        const TOKENIZED_REQUEST = req.clone({
            setHeaders: {
                Authorization: `Bearer ${this.authService.getToken}`
            }
        });

        return next.handle(TOKENIZED_REQUEST);
    }
}
